import { v4 as uuidv4 } from 'uuid';
import {
    Abstract
} from './Abstract';
import axios from "axios";

export class BWCustomer extends Abstract { 
    constructor(session) {
        super('BusinessWallet/CyberSource/Tokenization/Customer', session);
        this.buyerInformation_merchantCustomerID = uuidv4();
        this.buyerInformation_email = null;
        this.clientReferenceInformation_code = null;
        this.defaultPaymentInstrument_id = null;
        this.defaultShippingAddress_id = null;
        this.name = null;
        this.phone = null;
        this.shippings = [];
        this.paymentInstruments = [];
    }


    async deleteCustom(company,company_val,company_branch) {
       
        if (this.id == 0 || this.id == "" || this.id == null)
            throw "ERROR. Seleccione un registro para eliminar";
        await axios.delete(this.controller + "/Custom/" + this.id, 
        {
            headers: {
                user: this.user,
                company: company_val,
                branch:company_branch,
                companydb:company
            }
       
        });


 

    }

    async deleteCustomCliente(company_val,company_branch) {
       
        if (this.id == 0 || this.id == "" || this.id == null)
            throw "ERROR. Seleccione un registro para eliminar";
        await axios.delete(this.controller + "/" + this.id, 
        {
            headers: {
                user: this.user,
                company: company_val,
                branch:company_branch
            }
       
        });
    }
}