import axios from 'axios';


export class UniverseTransaction { 
    constructor(session) {
      if (session) 
      {
          this.user = session.user;
          this.id_company = session.company;
          this.id_branch = session.branch;
      }
      else 
      {
          this.user = null;
          this.id_company = null;
          this.id_branch = null;
      }
    }


  async companyDB() {
    let response = await axios.get('BusinessWallet/CyberSource/UniverseTransaction/CompanyBW' , {
        headers: {
            user: this.user,
            company: this.id_company,
            branch: this.id_branch
        }
    });
    return response.data;
    }
    
    async companyDBInfo(database) {
        let response = await axios.get('BusinessWallet/CyberSource/UniverseTransaction/CompanyBW/Info/' + database , {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }

async AllTrannsactions(Companydb,company,branch,initial_date,final_date,status,type,type_transaction) {
    let response = await axios.get('BusinessWallet/CyberSource/UniverseTransaction/AllTransactions' , {
        headers: {
            user: this.user,
            //company: this.id_company,
           // branch: this.id_branch,
            companyDB:Companydb,
            company:company,
            branch:branch,
            initial_date:initial_date,
            final_date:final_date,
            status:status,
            type:type,
            type_transaction:type_transaction ,
           }
    });
    return response.data;
}

async company(companyDB) {
    let response = await axios.get('BusinessWallet/CyberSource/UniverseTransaction/Company' , {
        headers: {
            user: this.user,
            company: this.id_company,
            branch: this.id_branch,
            companydb:companyDB
        }
    });
    return response.data;
}

async branch(companyDB,company) {
    let response = await axios.get('BusinessWallet/CyberSource/UniverseTransaction/Branch' , {
        headers: {
            user: this.user,
            company: company,
            branch: this.id_branch,
            companyDB:companyDB
        }
    });
    return response.data;
}
}
